import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TutorialGuard } from './services/tutorial/tutorial.guard';

const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule', canActivate: [TutorialGuard] },
  { path: 'a/:id/edit', loadChildren: './edit/edit.module#EditPageModule' },
  { path: 'my', loadChildren: './search/my/list.module#ListPageModule' },
  { path: 'a/:id', loadChildren: './search/detail/detail.module#DetailPageModule' },
  { path: 'favorites', loadChildren: './profile/favorites/favorite.module#FavoritePageModule' },
  { path: 'tutorial', loadChildren: './tutorial/tutorial.module#TutorialPageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
