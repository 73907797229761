import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authenticationservice';

import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private router: Router, public alertController: AlertController) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
          // auto logout if 401 response returned from api
        this.authenticationService.logout();
        this.router.navigate(['/tabs/profile']);
          // location.reload(true);
      }
      if (err.status === 404) {
        this.notFoundAlert(err.error.message);
        this.router.navigate(['/tabs/search']);
      }

      // const error = err.error.message || err.statusText;
      return throwError(err.error);
    }))
  }

  async notFoundAlert(message) {
    const alert = await this.alertController.create({
      header: 'Ошибка',
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }
}
